import { RouterProvider } from "react-router-dom";
import { router } from "./scenes/router";
import NavigationBar from "./components/navigation-bar";
import { useTranslation } from "react-i18next";
import Footer from "./components/footer";

function App() {
  return (
    <>
      <NavigationBar />
      <RouterProvider router={router} />
    </>
  );
}

export default App;

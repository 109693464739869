import { GlobeAltIcon, DocumentChartBarIcon, CpuChipIcon, ClipboardDocumentCheckIcon, CodeBracketIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function AboutUs() {
  const { t } = useTranslation();
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-[#6c6a92]">{t("about_us.header")}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("about_us.title")}</p>
          <p className="mt-6 text-md leading-8 text-gray-600">{t("about_us.text_1")}</p>
          <p className="mt-6 text-md leading-8 text-gray-600">{t("about_us.text_2")}</p>
          <p className="mt-6 text-md leading-8 text-gray-600">{t("about_us.text_3")}</p>
          <p className="my-20 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("about_us.references")}</p>
        </div>
        <div className="flex flex-col gap-y-8 lg:flex-row lg:gap-x-16 lg:gap-y-16 justify-center ">
          <a href="https://www.niltas.com.tr" target="_blank" rel="noreferrer" className="flex items-center justify-center">
            <img className="w-[15rem] max-w-none rounded-lg" src="images/niltas.png" alt="" />
          </a>
          <a href="https://www.bskhvac.com.tr/" target="_blank" rel="noreferrer" className="flex items-center justify-center">
            <img className="w-[15rem]  rounded-lg" src="images/bsk-logo.png" alt="" />
          </a>
          <a href="https://www.electroop.io/" target="_blank" rel="noreferrer" className="flex items-center justify-center">
            <img className="w-[15rem]  rounded-lg" src="images/electroop.svg" alt="" />
          </a>
        </div>
      </div>
    </div>
  );
}

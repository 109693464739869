import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="max-w-7xl mx-auto px-6 py-12 sm:px-8">
      <div className="flex flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 bg-white text-center md:justify-between">
        <img src="images/logo512-wide.png" alt="Goya" className="w-36" />
        <ul className="md:flex flex-wrap items-center gap-y-2 gap-x-8 sm:flex-nowrap">
          <li>
            <a as="a" href="/about-us" color="blue-gray" className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500 text-xs md:text-base">
              {t("footer.about_us")}
            </a>
          </li>
          <li>
            <a as="a" href="/privacy-policy" color="blue-gray" className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500 text-xs md:text-base">
              {t("footer.privacy_policy")}
            </a>
          </li>
          <li>
            <a as="a" href="/terms-and-conditions" color="blue-gray" className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500 text-xs md:text-base">
              {t("footer.terms_and_conditions")}
            </a>
          </li>
          <li>
            <a as="a" href="/contact" color="blue-gray" className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500 text-xs md:text-base">
              {t("footer.contact")}
            </a>
          </li>
        </ul>
      </div>
      <hr className="my-8 border-blue-gray-50" />
      <a color="blue-gray" className="text-center font-normal">
        &copy; {new Date().getFullYear()} {t("footer.rights")}
      </a>
    </footer>
  );
}

export default Footer;

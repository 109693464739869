import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

function PriivacyPolicy() {
  const [numPages, setNumPages] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const { i18n } = useTranslation();
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  const language = i18n.language || "en";
  return (
    <div className=" mx-auto px-6 py-12 sm:px-8 flex flex-col items-center">
      <Document file={`policies/privacy-policy-${language}.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width < 800 ? width - 20 : 800} renderTextLayer={false} renderAnnotationLayer={false} />
        ))}
      </Document>
    </div>
  );
}

function TermsAndConditions() {
  const [numPages, setNumPages] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const { i18n } = useTranslation();
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  let language = i18n.language || "en";
  return (
    <div className=" mx-auto px-6 py-12 sm:px-8 flex flex-col items-center">
      <Document file={`policies/user-agreement-${language}.pdf`} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (el, index) => (
          <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width < 800 ? width - 20 : 800} renderTextLayer={false} renderAnnotationLayer={false} />
        ))}
      </Document>
    </div>
  );
}

export { PriivacyPolicy, TermsAndConditions };

import { useTranslation } from "react-i18next";

export default function NotFound() {
  const { t } = useTranslation();
  return (
    <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-[#adaad8]">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("not_found.title")}</h1>
          <p className="mt-6 text-base leading-7 text-gray-600">{t("not_found.subtitle")}</p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="/"
              className="rounded-md bg-[#adaad8] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#696783] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("not_found.button_1")}
            </a>
            <a href="/contact" className="text-sm font-semibold text-gray-900">
              {t("not_found.button_2")}
            </a>
          </div>
        </div>
      </main>
    </>
  );
}

import { useState } from "react";
import { ChevronDownIcon, EnvelopeIcon, MapIcon, PhoneArrowDownLeftIcon } from "@heroicons/react/20/solid";
import { Switch } from "@headlessui/react";
import { Map, Marker } from "pigeon-maps";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Contact() {
  const { t } = useTranslation();
  const [agreed, setAgreed] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const form = useRef();

  return (
    <div className="isolate bg-white px-6 py-24 sm:py-32 lg:px-8">
      <div className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]" aria-hidden="true">
        <div
          className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#88a5d9] to-[#adaad8] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
          style={{
            clipPath:
              "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
          }}
        />
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("contact_us.header")}</h2>
      </div>
      <div className="flex flex-col gap-y-8 mt-16 sm:mt-20 lg:flex-row lg:gap-x-16 lg:gap-y-16 justify-between mx-auto max-w-7xl">
        <div className="flex flex-col gap-y-4">
          <h2 className="text-2xl mb-5 font-semibold text-gray-900">Goya Teknoloji Limited Şirketi</h2>
          <p className="text-lg mb-20 text-gray-600">{t("contact_us.text")}</p>
          <div className="flex">
            <PhoneArrowDownLeftIcon className="h-6 w-6" />
            <a href="tel:+902127064692" className="ml-2  font-semibold">
              <p className="ml-5 font-semibold">+90 (212) 706 46 92</p>
            </a>
          </div>
          <div className="flex  ">
            <EnvelopeIcon className="h-6 w-6" />
            <a href="mailto:info@goyatech.com.tr" className="ml-2  font-semibold">
              <p className="ml-5 font-semibold">info@goyatech.com.tr</p>
            </a>
          </div>
          <div className="flex  ">
            <MapIcon className="h-6 w-6" />
            <a href="https://maps.app.goo.gl/5SZt4GCKgr2YANs49" target="_blank" rel="noopener noreferrer" className="ml-2  font-semibold">
              <p className="ml-5 font-semibold">42 Maslak B Blok, Kolektif House, Sarıyer/İstanbul 34398</p>
            </a>
          </div>
          <div className="flex gap-x-4 flex justify-center mt-10">
            <Map height={300} width={500} defaultCenter={[41.1137244, 29.0186246]} defaultZoom={15}>
              <Marker width={40} anchor={[41.1137244, 29.0216246]} />
            </Map>
          </div>
        </div>
        <form
          className="mx-auto mt-16 max-w-md sm:mt-20"
          ref={form}
          onSubmit={(e) => {
            if (!agreed) return alert(t("contact_us.form.agree_error"));
            if (sent) return alert(t("contact_us.form.sent_error"));
            e.preventDefault();
            const formData = new FormData(e.target);
            const formProps = Object.fromEntries(formData);
            emailjs.sendForm("service_r29vds4", "template_ciufidl", form.current, "MeCn2I7seAHc_gfQ7").then(
              (result) => {
                setSent(true);
              },
              (error) => {
                setError(true);
              }
            );
          }}
        >
          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div className="sm:col-span-2 text-center">
              <p className="mt-2 text-lg leading-8 text-gray-600">{t("contact_us.form.text")}</p>
            </div>
            <div>
              <label htmlFor="first-name" className="block text-sm font-semibold leading-6 text-gray-900">
                {t("contact_us.form.first_name")}
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="name"
                  id="name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div>
              <label htmlFor="last-name" className="block text-sm font-semibold leading-6 text-gray-900">
                {t("contact_us.form.last_name")}
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="company" className="block text-sm font-semibold leading-6 text-gray-900">
                {t("contact_us.form.company")}
              </label>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="company"
                  id="company"
                  autoComplete="organization"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                {t("contact_us.form.email")}
              </label>
              <div className="mt-2.5">
                <input
                  type="email"
                  name="email"
                  id="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="phone-number" className="block text-sm font-semibold leading-6 text-gray-900">
                {t("contact_us.form.phone")}
              </label>
              <div className="relative mt-2.5">
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  className="block w-full rounded-md border-0 px-3.5 py-2  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-semibold leading-6 text-gray-900">
                {t("contact_us.form.message")}
              </label>
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
              </div>
            </div>
            <Switch.Group as="div" className="flex gap-x-4 sm:col-span-2">
              <div className="flex h-6 items-center">
                <Switch
                  checked={agreed}
                  onChange={setAgreed}
                  className={classNames(
                    agreed ? "bg-indigo-600" : "bg-gray-200",
                    "flex w-8 flex-none cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  )}
                >
                  <span className="sr-only">Agree to policies</span>
                  <span
                    aria-hidden="true"
                    className={classNames(agreed ? "translate-x-3.5" : "translate-x-0", "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out")}
                  />
                </Switch>
              </div>
              <div className="flex flex-col">
                {t("contact_us.form.agree")}{" "}
                <a href="/privacy-policy" className="font-semibold text-[#6c6a92]">
                  {t("contact_us.form.privacy_policy")}
                </a>
              </div>
            </Switch.Group>
          </div>
          <div className="mt-10">
            {sent ? (
              <div class="bg-teal-100 mb-5 rounded-b text-teal-900 px-4 py-3 shadow-md" role="alert">
                <div class="flex">
                  <div class="p-1">
                    <CheckCircleIcon class="h-6 w-6 text-teal-500" />
                  </div>
                  <div>
                    <p class="font-bold">{t("contact_us.form.success")}</p>
                    <p class="text-sm">{t("contact_us.form.success_text")}</p>
                  </div>
                </div>
              </div>
            ) : null}
            {error ? (
              <div class="bg-red-100 mb-5 rounded-b text-red-900 px-4 py-3 shadow-md" role="alert">
                <div class="flex">
                  <div class="p-1">
                    <XCircleIcon class="h-6 w-6 text-red-500" />
                  </div>
                  <div>
                    <p class="font-bold">{t("contact_us.form.error")}</p>
                    <p class="text-sm">{t("contact_us.form.error_text")}</p>
                  </div>
                </div>
              </div>
            ) : null}
            <button
              type="submit"
              className="block w-full rounded-md bg-[#6c6a92] px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-[#a29edb] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {t("contact_us.form.button")}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

import { createBrowserRouter } from "react-router-dom";
import Home from "./home";
import IoTPlatform from "./iot-platform";
import MobileApp from "./mobile-app";
import WebApplications from "./web-applications";
import PCBDesign from "./pcb-design";
import EmbeddedSoftware from "./embedded-software";
import AboutUs from "./about-us";
import Contact from "./contact";
import NotFound from "./not-found";
import HowItWorks from "./how-it-works";
import Footer from "../components/footer";
import { PriivacyPolicy, TermsAndConditions } from "./policies";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/how-it-works",
    element: (
      <>
        <HowItWorks />
        <Footer />
      </>
    ),
  },
  {
    path: "/iot-platform",
    element: (
      <>
        <IoTPlatform />
        <Footer />
      </>
    ),
  },
  {
    path: "/mobile-app",
    element: (
      <>
        <MobileApp />
        <Footer />
      </>
    ),
  },
  {
    path: "/web-applications",
    element: (
      <>
        <WebApplications />
        <Footer />
      </>
    ),
  },
  {
    path: "/pcb-design",
    element: (
      <>
        <PCBDesign />
        <Footer />
      </>
    ),
  },
  {
    path: "/embedded-software",
    element: (
      <>
        <EmbeddedSoftware />
        <Footer />
      </>
    ),
  },
  {
    path: "/about-us",
    element: (
      <>
        <AboutUs />
        <Footer />
      </>
    ),
  },
  {
    path: "/contact",
    element: (
      <>
        <Contact />
        <Footer />
      </>
    ),
  },
  {
    path: "/privacy-policy",
    element: (
      <>
        <PriivacyPolicy />
        <Footer />
      </>
    ),
  },
  {
    path: "/terms-and-conditions",
    element: (
      <>
        <TermsAndConditions />
        <Footer />
      </>
    ),
  },
  {
    path: "/*",
    element: (
      <>
        <NotFound />
        <Footer />
      </>
    ),
  },
]);

import { ArrowPathIcon, LockClosedIcon, ArrowRightCircleIcon, ArrowRightEndOnRectangleIcon, UserCircleIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export default function MobileApp() {
  const { t } = useTranslation();
  const features = [
    {
      name: t("mobile_app.features.feature_1.title"),
      description: t("mobile_app.features.feature_1.description"),
      icon: ArrowRightCircleIcon,
    },
    {
      name: t("mobile_app.features.feature_2.title"),
      description: t("mobile_app.features.feature_2.description"),
      icon: LockClosedIcon,
    },
    {
      name: t("mobile_app.features.feature_3.title"),
      description: t("mobile_app.features.feature_3.description"),
      icon: ArrowPathIcon,
    },
    {
      name: t("mobile_app.features.feature_4.title"),
      description: t("mobile_app.features.feature_4.description"),
      icon: ArrowRightEndOnRectangleIcon,
    },
  ];
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-7 text-[#6c6a92]">{t("mobile_app.header")}</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("mobile_app.title")}</p>
          <p className="mt-6 text-lg leading-8 text-gray-600">{t("mobile_app.subtitle")}</p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-10 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute left-0 top-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#6c6a92]">
                    <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
      <div className="px-6 mt-24 sm:px-2 md:px-3 lg:px-8 bg-white"></div>
      <div className="mx-5 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="lg:max-w-lg">
              <p className="text-base font-semibold leading-7 text-[#6c6a92]">{t("mobile_app.description.header")}</p>
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t("mobile_app.description.title")}</h1>
              <p className="mt-6 text-xl leading-8 text-gray-700">{t("mobile_app.description.subtitle")}</p>
            </div>
          </div>
        </div>
        <div className="-mt-12 p-12 lg:sticky lg:top-40 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden flex-col items-center justify-center  ">
          <img className=" w-[48rem] max-w-base flex justify-center align-middle rounded-lg   sm:w-[32rem]" src="images/mobile-app-1.png" alt="" />
          <img className="w-[48rem] max-w-base flex justify-center align-middle rounded-lg   sm:w-[32rem]" src="images/mobile-app-2.png" alt="" />
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="max-w-xl text-base leading-7 text-gray-700 lg:max-w-lg">
              <p>{t("mobile_app.description.description")}</p>
              <ul role="list" className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  <UserCircleIcon className="mt-1 h-5 w-5 flex-none text-[#6c6a92]" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">{t("mobile_app.description.feature_1.title")} </strong> {t("mobile_app.description.feature_1.description")}
                  </span>
                </li>
                <li className="flex gap-x-3">
                  <AdjustmentsHorizontalIcon className="mt-1 h-5 w-5 flex-none text-[#6c6a92]" aria-hidden="true" />
                  <span>
                    <strong className="font-semibold text-gray-900">{t("mobile_app.description.feature_2.title")} </strong> {t("mobile_app.description.feature_2.description")}
                  </span>
                </li>
              </ul>
              <p className="mt-8">{t("mobile_app.description.conclusion")}</p>
              <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">{t("mobile_app.description.end_title")}</h2>
              <p className="mt-6">{t("mobile_app.description.end_description")}</p>
              <div className="mt-10 flex  gap-x-4">
                <a
                  href="/contact"
                  className="rounded-md bg-[#6c6a92] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#a29edb] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("mobile_app.description.button")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

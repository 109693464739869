import { ChatBubbleBottomCenterIcon, ChatBubbleLeftEllipsisIcon, DevicePhoneMobileIcon, LinkIcon, WrenchIcon } from "@heroicons/react/20/solid";
import { useTranslation } from "react-i18next";

function HowItWorks() {
  const { t } = useTranslation();
  return (
    <div className="grid min-h-full place-items-center bg-white mx-4 py-24 sm:py-32 lg:px-8 ">
      <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{t("how_we_work.title")}</h1>
      <div className="mt-6 text-base leading-7 text-gray-600 sm:max-w-0 md:max-w-2xl  py-4 rounded-lg">
        <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("how_we_work.step_1.title")}</h2>
        <div className="flex items-center sm:flex-col md:flex-row">
          <ChatBubbleLeftEllipsisIcon className="text-[#adaad8] m-10  md:max-w-2xl sm:m-5 max-w-[10rem]" />
          <p className="mt-6 text-base leading-7 text-gray-600">{t("how_we_work.step_1.description")}</p>
        </div>
      </div>
      <div className="mt-6 text-base leading-7 text-gray-600 sm:max-w-0 md:max-w-2xl  py-4 rounded-lg">
        <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("how_we_work.step_2.title")}</h2>
        <div className="flex items-center ">
          <div className="flex items-center sm:flex-col md:flex-row">
            <p className=" text-base leading-7 text-gray-600 mt-4">{t("how_we_work.step_2.description")}</p>
          </div>
          <LinkIcon className="text-[#adaad8] m-10  md:max-w-2xl sm:m-5 max-w-[10rem]" />
        </div>
        <div className="mt-6 text-base leading-7 text-gray-600 sm:max-w-0 md:max-w-2xl  py-4 rounded-lg">
          <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("how_we_work.step_3.title")}</h2>
          <div className="flex items-center ">
            <DevicePhoneMobileIcon className="text-[#adaad8] m-10  md:max-w-2xl sm:m-5 max-w-[10rem]" />
            <p className="mt-6 text-base leading-7 text-gray-600">{t("how_we_work.step_3.description")}</p>
          </div>
        </div>
        <div className="mt-10 text-base leading-7 text-gray-600">
          <h2 className="mt-6 text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">{t("how_we_work.step_4.title")}</h2>
          <div className="flex items-center ">
            <p className="mt-6 text-base leading-7 text-gray-600">{t("how_we_work.step_4.description")}</p>
            <WrenchIcon className="text-[#adaad8] m-10  md:max-w-2xl sm:m-5 max-w-[10rem]" />
          </div>
        </div>
        <div className="flex items-center justify-center gap-x-6 mt-10">
          <button className="rounded-md bg-[#6c6a92] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#a29edb] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
            {t("how_we_work.button")}
          </button>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
